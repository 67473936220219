

const OrganizationsAdmin = () => {

    

    return(
        <h1>HELLO!</h1>
    );
}

export default OrganizationsAdmin;