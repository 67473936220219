import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiRequest from "../../../../helpers/apiRequest";
import { errorFieldHandler, errorFieldMessage, errorHandler, errorProps } from "../../../../helpers/errorHelper";
import { notify } from "../../../../helpers/notify";
import BasicDatePicker from "../../../DatePicker";
import Accessories from "../../../aeds/accessory/index";
import ModelSelect from "../../../aeds/modal/modelSelect";
import ModelSelectList from "../../../aeds/modal/modelSelectList";
import { format } from "date-fns";

export function AddAED({ onAdd = () => {}, defaultLocation }) {
    const { organizationId } = useParams();

    const [selectAEDModal, setSelectAedModal] = useState(false);
    const [addWithoutLocation, setWithoutLocation] = useState(false);

    const [selectedAEDModel, setSelectedAEDModel] = useState(null);
    const [serial, setSerial] = useState(null);
    const [dateInService, setDateInService] = useState(null); // purchase date
    const [originalPurchaseDateIsNotKnown, setOriginalPurchaseDateIsNotKnown] = useState(false);
    const [placement, setPlacement] = useState(null);
    const [location, setLocation] = useState(defaultLocation);
    const [accessories, setAccessories] = useState([]);

    const [locationsList, setLocationsList] = useState([]);
    async function getOrganizationLocations() {
        const locationsRes = await apiRequest.get(`/location/all/${organizationId}`).then((res) => res.data);
        setLocationsList(locationsRes);
    }

    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (!addWithoutLocation) {
            setPlacement(null);
            setLocation(defaultLocation);
        }
    }, [addWithoutLocation]);

    async function addAed() {
        const purchaseDate = new Date(dateInService);

        if (!originalPurchaseDateIsNotKnown && (dateInService === null || purchaseDate.toString() === "Invalid Date")) {
            setErrors((prevState) => [...prevState, { field: "purchaseDate", message: "Required" }]);
            notify("Please, enter a valid date. e.g: MM/dd/yyyy", "alert");
            return;
        }

        await apiRequest
            .post("/aed", {
                aedModelId: selectedAEDModel?.id,
                serial: serial,
                purchaseDate: originalPurchaseDateIsNotKnown ? null : format(purchaseDate, "yyyy-MM-dd"),
                originalPurchaseDateIsNotKnown: originalPurchaseDateIsNotKnown,
                accessories: accessories.map((accessory) => {
                    delete accessory.id;
                    return accessory;
                }),
                organizationId: organizationId,
                withoutLocation: addWithoutLocation,
                ...(!addWithoutLocation
                    ? {
                          locationId: location?.id ? location?.id : location,
                          placement: placement,
                      }
                    : {}),
            })
            .then(async (res) => {
                notify("AED added", "success");
                onAdd();
                setErrors([]);
            })
            .catch((err) => {
                notify(err.response?.data?.errors[0]?.message, "error");
                setErrors([...errorHandler(err)]);
            });
    }

    const handleOriginalPurchaseDate = (value) => {
        if (value) setErrors((prevState) => prevState.filter((err) => err.field !== "purchaseDate"));

        setOriginalPurchaseDateIsNotKnown(value);
        setDateInService(null);
    };

    useEffect(() => {
        getOrganizationLocations();
    }, []);

    return (
        <AnimatePresence>
            <Typography variant="h6">Add new AED</Typography>

            {!selectAEDModal && (
                <motion.div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ModelSelect {...errorProps(errors, "aedModelId")} aed={selectedAEDModel} onClickToChange={() => setSelectAedModal(true)} />
                        </Grid>

                        <Grid item xs={12} md={4} lg={4}>
                            <TextField
                                helperText={errorFieldMessage(errors, "serial")}
                                error={errorFieldHandler(errors, "serial")}
                                fullWidth
                                onChange={(e) => setSerial(e.target.value)}
                                size="small"
                                variant="outlined"
                                label="Serial number"
                            />
                        </Grid>

                        <Grid item xs={12} md={4} lg={4}>
                            {originalPurchaseDateIsNotKnown ? (
                                <BasicDatePicker
                                    key="dateOff"
                                    isDisabled={true}
                                    value={null}
                                    InputProps={{
                                        size: "small",
                                        fullWidth: true,
                                        label: "Purchase Date",
                                        helperText: errorFieldMessage(errors, "purchaseDate"),
                                        error: errorFieldHandler(errors, "purchaseDate"),
                                    }}
                                    PickerProps={{
                                        views: ["month", "year"],
                                    }}
                                />
                            ) : (
                                <BasicDatePicker
                                    key="dateOn"
                                    isDisabled={originalPurchaseDateIsNotKnown}
                                    value={dateInService}
                                    onChange={(date) => {
                                        setDateInService(date);
                                    }}
                                    InputProps={{
                                        size: "small",
                                        fullWidth: true,
                                        label: "Purchase Date",
                                        helperText: errorFieldMessage(errors, "purchaseDate"),
                                        error: errorFieldHandler(errors, "purchaseDate"),
                                    }}
                                />
                            )}
                        </Grid>

                        <Grid item xs={12} md={4} lg={4}>
                            <FormGroup fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={originalPurchaseDateIsNotKnown} onChange={(e) => handleOriginalPurchaseDate(e.target.checked)} />
                                    }
                                    label="Original Purchase Date Is Not Known"
                                />
                            </FormGroup>
                        </Grid>

                        <Accessories
                            aedModel={selectedAEDModel}
                            {...errorProps(errors, "accessories")}
                            onAccessoriesListChange={(list) => setAccessories(list)}
                        />

                        {defaultLocation == null && (
                            <Grid item xs={12}>
                                <FormGroup fullWidth>
                                    <FormControlLabel
                                        control={<Checkbox checked={addWithoutLocation} onChange={(e) => setWithoutLocation(e.target.checked)} />}
                                        label="Add without location"
                                    />
                                </FormGroup>
                            </Grid>
                        )}

                        {!addWithoutLocation && (
                            <>
                                {defaultLocation == null && (
                                    <Grid item xs={12} md={6}>
                                        <FormControl {...errorProps(errors, "locationId")} fullWidth>
                                            <InputLabel id="demo-simple-select-label">Location</InputLabel>
                                            <Select
                                                {...errorProps(errors, "locationId")}
                                                MenuProps={{ sx: { maxHeight: 250 } }}
                                                fullWidth
                                                value={location}
                                                onChange={(e) => setLocation(e.target.value)}
                                            >
                                                {locationsList?.map((loc) => (
                                                    <MenuItem key={"loc_" + loc.id} value={loc.id}>
                                                        {loc?.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>{errorProps(errors, "locationId").helperText}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                )}

                                <Grid item xs={12} md={defaultLocation == null ? 6 : 12}>
                                    <TextField
                                        {...errorProps(errors, "placement")}
                                        fullWidth
                                        onChange={(e) => setPlacement(e.target.value)}
                                        variant="outlined"
                                        placeholder="Placement (e.g. First floor, break room, on wall near door)"
                                        label="Placement"
                                    />
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12}>
                            <Button onClick={() => addAed()} variant="contained" fullWidth>
                                ADD AED
                            </Button>
                        </Grid>
                    </Grid>
                </motion.div>
            )}

            {selectAEDModal && (
                <motion.div initial={{ x: -100 }} animate={{ x: 0 }} exit={{ x: 0 }}>
                    <ModelSelectList
                        onSelect={(aedModel) => {
                            setSelectedAEDModel(aedModel);
                            setSelectAedModal(false);
                        }}
                    />
                </motion.div>
            )}
        </AnimatePresence>
    );
}
